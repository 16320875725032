import styled from 'styled-components';
import { Button as UiButton } from '@swordhealth/ui-corporate';
import NextImage from 'next/image';

export const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    flex-direction: column;
  }
`;

export const LogoWrapper = styled.div`
  margin-bottom: 32px;
  height: 24px;
`;

export const Button = styled(UiButton)`
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 9px 24px;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const Image = styled(NextImage)`
  position: absolute;
  bottom: 0;
  right: 0;
  transition: all 0.3s ease;
  object-fit: contain;
  object-position: right bottom;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    bottom: -92px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    height: 282px;
    width: 90px;
    bottom: -48px;
    object-position: left bottom;
    object-fit: cover;
  }
`;

export const Card = styled.div`
  border-radius: 32px;
  width: 100%;
  background: ${(props) => (props.$white ? 'white' : 'var(--Neutral-shades-Neutral-300, #efeae6)')};
  border: ${(props) => props.$white && 'white solid 2px'};
  overflow: hidden;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: ${(props) =>
      props.$white
        ? '#EEF4FD'
        : 'linear-gradient(328deg, #e6dcd4 8.52%, #efeae6 51.92%, rgba(230, 220, 212, 0.8) 95.32%)'};
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  p {
    color: #5c606b;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    display: flex;
    width: 33%;
  }

  &:hover {
    border: ${(props) => (props.$white ? '#5993EA solid 2px' : 'none')};
    &::before {
      opacity: 1;
    }
    & > div > div > button {
      background-color: var(--button-hover-bg);
      border-color: var(--button-hover-border);
      color: var(--button-hover-color);
    }

    ${Image} {
      transform: scale(1.02);
    }
  }
`;

export const CardInner = styled.div`
  padding: ${(props) => (props.$withoutImage ? '32px' : '32px 106px 32px 32px')};
  border-radius: 16px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    min-height: 352px;
    padding: ${(props) => (props.$withoutImage ? '32px' : '32px 141px 32px 32px')};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    padding: ${(props) => (props.$withoutImage ? '32px' : '32px 165px 32px 32px')};
  }
`;

export const Content = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  margin-bottom: 32px;
  min-height: ${(props) => (props.$withoutImage ? 'unset' : '144px')};

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    min-height: 196px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    min-height: ${(props) => (props.$withoutImage ? 'unset' : '96px')};
  }
  @media (min-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-bottom: 24px;
  }
`;
