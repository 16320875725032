import Image from 'next/image';

import { Button, Heading, Paragraph, SectionHeader } from '@swordhealth/ui-corporate';

import {
  Wrapper,
  CustomModal,
  CloseButton,
  IconClose,
  Content,
  ButtonWrapper,
  UnitHeader,
  ImageWrapper,
  HeaderContent,
} from './styles';
import { ArrowButton } from '../styles';

import getImgSrcWorkaround from '@/utils/images';
import { products } from '@/utils/products';
import { ACTIONS, trackButtonClicked } from '@/utils/track';

export default function SideModal({
  title,
  subtitle,
  solutionConditions,
  modalIsOpen,
  setModalIsOpen,
}) {
  const handleConditionClick = (condition, solution) => {
    document.documentElement.classList.remove('ui-html-no-scroll');
    trackButtonClicked({
      action: ACTIONS.REDIRECT_TO_COVERAGE,
      buttonText: condition?.label,
      buttonLocation: 'Drawer',
      buttonSolution: solution?.product?.name,
    });
  };

  return (
    <CustomModal
      centeredContent={false}
      open={modalIsOpen}
      onClose={() => setModalIsOpen(false)}
      showCloseButton={false}
    >
      <Wrapper id="modal-react">
        <HeaderContent>
          <SectionHeader title={title} titleSize="2xl" description={subtitle} xAlign="1" />
          <CloseButton
            aria-label="Close"
            type="button"
            onClick={() => setModalIsOpen(false)}
            data-testid="closeModal"
          >
            <IconClose />
          </CloseButton>
        </HeaderContent>
        <Content>
          {solutionConditions?.map((solution) => {
            const { product, conditions } = solution;
            if (conditions.length > 0)
              return (
                <>
                  <UnitHeader>
                    <ImageWrapper>
                      <Image
                        fill
                        src={
                          product?.logo
                            ? getImgSrcWorkaround(product?.logo?.url)
                            : products[product?.productKey.toUpperCase()]?.logo
                        }
                      />
                    </ImageWrapper>
                    <Heading as="h3" size="xl">
                      {solution?.title}
                    </Heading>
                  </UnitHeader>
                  <ButtonWrapper>
                    {conditions.map((condition, index) => (
                      <Button
                        key={`conditions_${product?.productKey}_${index}`}
                        variant="text"
                        href={`/eligibility-check?solution=${
                          product?.productKey
                        }&hide_get_started=true&program_type=${
                          products[product?.productKey.toUpperCase()]?.param
                        }`}
                        onClick={() => handleConditionClick(condition, solution)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <>
                          <Paragraph size="lg">{condition.label}</Paragraph>
                          <ArrowButton />
                        </>
                      </Button>
                    ))}
                  </ButtonWrapper>
                </>
              );
          })}
        </Content>
      </Wrapper>
    </CustomModal>
  );
}
