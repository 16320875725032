import styled from 'styled-components';
import ArrowSVG from '/public/icons/arrow.svg';
import Section from '@/components/ui/Section';
import { Container } from '@/components/ui/flexbox';
import { Button } from '@swordhealth/ui-corporate';

export const StyledSection = styled(Section)`
  clip-path: circle(farthest-side at 50% -10000px);
  margin-bottom: calc(120px + 35px);

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    clip-path: circle(farthest-side at 50% -4000px);
    margin-bottom: calc(96px + 25px);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    clip-path: circle(farthest-side at 50% -2500px);
    margin-bottom: calc(64px + 25px);
  }
`;

export const StyledContainer = styled(Container)`
  position: relative;
  padding-top: 80px;
`;

export const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 64px;
  justify-content: space-between;
  align-items: center;
`;
export const TitlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 40px;
  margin-top: 20px;

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    margin-top: 80px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-bottom: 24px;
  }
`;

export const ArrowButton = styled(ArrowSVG)`
  height: 16px;
  width: 9px;
  path {
    fill: ${(props) => props.theme.colors.grey[900]};
  }
`;

export const DesktopButton = styled(Button)`
  display: block;
  &:hover,
  &:focus {
    & > span > svg > path {
      fill: var(--button-hover-color);
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: none;
  }
`;

export const TabletButton = styled(Button)`
  display: none;
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: block;
    margin: auto;
    margin-top: var(--ui-spacings-xl);
    &:hover,
    &:focus {
      & > span > svg > path {
        fill: var(--button-hover-color);
      }
    }
  }
`;
