import { useContext } from 'react';
import NextImage from 'next/image';

import ClientContext from '@/contexts/client.context';

import getImgSrcWorkaround from '@/utils/images';
import { products } from '@/utils/products';

import { Heading, Paragraph } from '@swordhealth/ui-corporate';
import { getClient } from '@/services/client.service';
import { compareDates } from '@/utils/dates';
import { getOnboardingURL } from '@/utils/onboarding-urls';
import { ACTIONS, BUTTON_TYPES, trackButtonClicked } from '@/utils/track';

import { Wrapper, Card, CardInner, LogoWrapper, Content, Image, Button } from './styles';

export default function Cards({ cards, selectProduct, client = null, country = null }) {
  const { setProduct } = useContext(ClientContext);

  const cardsWithoutImage = cards.filter(
    (card) => !getImgSrcWorkaround(card?.image?.url ?? card?.image?.data?.attributes.url),
  );

  const handleCardClick = async (item) => {
    if (!client) {
      selectProduct(products[item.solution.toUpperCase()]);
      setProduct(products[item.solution.toUpperCase()]);
      trackButtonClicked({
        action: ACTIONS.REDIRECT_TO_COVERAGE,
        buttonType: BUTTON_TYPES.NAVIGATION,
        buttonText: item?.button_label
          ? `${item?.button_label} - ${item.solution}`
          : `Get started - ${item.solution}`,
        destination: `/eligibility-check?solution=${item.solution}`,
        buttonLocation: 'card',
      });
      window.open(`/eligibility-check?solution=${item.solution}`, '_blank', 'noopener,noreferrer');
    } else {
      const fetchedClient = await getClient({
        client: client,
        productKey: item?.solution,
      });

      if (fetchedClient && compareDates(fetchedClient?.launchDate)) {
        return window.open(
          `/waiting-list?client=${fetchedClient.clientKey}`,
          '_blank',
          'noopener,noreferrer',
        );
      }
      const url = getOnboardingURL({
        client: fetchedClient,
        country: country,
        programType: products[item.solution.toUpperCase()].param,
      });

      trackButtonClicked({
        action: ACTIONS.REDIRECT_TO_ONBOARDING,
        buttonType: BUTTON_TYPES.NAVIGATION,
        buttonText: item?.button_label ?? `Get started - ${item.solution}`,
        destination: url,
        buttonLocation: 'form',
      });
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <Wrapper>
      {cards.map((item) => (
        <Card
          key={item.solution}
          onClick={() => handleCardClick(item)}
          role="button"
          $white={!!client}
        >
          <CardInner $withoutImage={cardsWithoutImage.length > 0}>
            <div>
              <LogoWrapper>
                {item.logo?.data && (
                  <NextImage
                    src={getImgSrcWorkaround(item?.logo?.url ?? item?.logo?.data?.attributes.url)}
                    alt=""
                    height={24}
                    width={59}
                  />
                )}
              </LogoWrapper>

              <Content $withoutImage={cardsWithoutImage.length > 0}>
                <Heading size="xl" as="h2">
                  {item.title}
                </Heading>
                {item.description && (
                  <Paragraph as="h3" size="sm">
                    {item.description}
                  </Paragraph>
                )}
              </Content>
              {cardsWithoutImage.length === 0 && item.image?.data && (
                <Image
                  src={getImgSrcWorkaround(item?.image?.url ?? item?.image?.data?.attributes.url)}
                  alt={item.title}
                  width={125}
                  height={302}
                />
              )}
            </div>
            <div>
              <Button type="button" size="sm" target="_blank" rel="noopener noreferrer">
                {item?.button_label ?? 'Get started'}
                {cardsWithoutImage.length > 0 && (
                  <img src={'/assets/arrow_up.svg'} alt={'arrow_up'} />
                )}
              </Button>
            </div>
          </CardInner>
        </Card>
      ))}
    </Wrapper>
  );
}
